import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { sand } from 'src/styles/newColors'

export const Wrapper = styled.section`
  background: ${grayscale[100]};
`

export const Section = styled.div`
  padding-bottom: 40px;

  background: ${grayscale[100]};

  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Row = styled.div`
  @media ${device.tablet} {
    display: flex;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;

    @media ${device.tablet} {
      font-size: 18px;
      line-height: 22px;
    }

    @media ${device.desktopLG} {
      font-size: 28px;
      line-height: 33px;
    }

    @media ${device.desktopXL} {
      font-size: 32px;
      line-height: 38px;
    }
  }
`

export const Col = styled.div`
  background-color: ${sand};  
`

export const Sub = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  color: #161616;
  margin-bottom: 3px;

  @media ${device.tablet} {
    text-align: left;
    font-size: 12px;
  }

  @media ${device.desktopLG} {
    text-align: left;
    font-size: 16px;
    margin-bottom: 8px;
  }

  @media ${device.desktopXL} {
    text-align: left;
    font-size: 18px;
  }
`
export const TopicsWrapper = styled.div`
  display: flex;

  p {
    font-size: 7.8px;
    line-height: 10px;

    @media ${device.desktopLG} {
      font-size: 11px;
      line-height: 13px;
    }

    @media ${device.desktopXL} {
      font-size: 15px;
      line-height: 18px;
    }
  }
`

export const Separator = styled.div`
  height: 1px;
  padding: 0 24px;

  div {
    border: 1px dashed ${grayscale[200]};
  }
`
