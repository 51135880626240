import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Hero } from './sections/hero/_index'
import { OMelhorPixEstaAqui } from './sections/oMelhorPixEstaAqui/_index'
import { TransacoesSeguras } from './sections/transacoesSeguras/_index'
import { Golpes } from './sections/golpes/_index'
import { Dicas } from './sections/dicas/_index'
import { MaisInformacoes } from './sections/maisInformacoes/_index'
import { CanaisOficiais } from './sections/canaisOficiais/_index'
import EmpresasParceiras from './sections/empresas-parceiras/_index'
import { Perguntas } from './sections/perguntas/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const Seguranca = () => {
  const lang = 'pt'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <OMelhorPixEstaAqui lang={lang} />
        <TransacoesSeguras lang={lang} />
        <Golpes lang={lang} />
        <Dicas lang={lang} />
        <MaisInformacoes lang={lang} />
        <CanaisOficiais lang={lang} />
        <Perguntas lang={lang} />
        <EmpresasParceiras lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default Seguranca
