import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// translate
import textEN from 'src/pages/seguranca/assets/data/translate/oMelhorPixEstaAqui/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/oMelhorPixEstaAqui/textPT.json'
import { ContentType, ParagraphProps } from 'src/pages/seguranca/assets/data/translate/oMelhorPixEstaAqui/types'

import * as S from './style'
interface ITransacoesSeguras {
  lang: string;
}

export const OMelhorPixEstaAqui = ({ lang }: ITransacoesSeguras) => {
  const { title, paragraph, ctaText, ctaLink, altText }: ContentType = lang === 'pt' ? textPT : textEN
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section role='img' aria-label={altText} className='py-5 d-flex align-items-md-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7'>
            <h2 className='fs-24 fs-md-20 fs-lg-28 fs-xl-32 lh-28 lh-md-24 lh-lg-33 lh-xl-38'>{title}</h2>
            {
              paragraph.map((item: ParagraphProps) => (
                <p key={item.p} className='fs-12 fs-md-16 fs-xl-18 lh-18 lh-xl-22'>{item.p}</p>
              ))
             }
            <Link
              title='Conheça o Pix do Inter'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to={ctaLink}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Conheça o Pix do Inter',
                  section_name: 'O melhor Pix está aqui!',
                  redirect_url: ctaLink,
                })
              }}
            >
              {ctaText}
            </Link>
          </div>
        </div>
      </div>
    </S.Section>
  )
}
