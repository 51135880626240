import React from 'react'

// translate
import textEN from 'src/pages/seguranca/assets/data/translate/transacoesSeguras/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/transacoesSeguras/textPT.json'
import { ContentType } from 'src/pages/seguranca/assets/data/translate/transacoesSeguras/types'

import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'
interface ITransacoesSeguras {
  lang: string;
}

export const TransacoesSeguras = ({ lang }: ITransacoesSeguras) => {
  const { title, description, image, altText }: ContentType = lang === 'pt' ? textPT : textEN
  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 offset-xl-1 order-md-last'>
            <h2 className='fs-24 fs-md-28 fs-lg-40 lh-28 lh-md-33 lh-lg-44'>{title}</h2>
            <p className='fs-10 fs-lg-14 fs-xl-16 lh-13 lh-lg-17 lh-xl-20'>{description}</p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 mt-3 d-flex justify-content-center justify-content-md-start'>
            <ImageWebp
              arrayNumbers={[ 312, 282, 376, 552 ]}
              pathSrc={image}
              altDescription={altText}
            />
          </div>
        </div>
      </div>
    </S.Section>
  )
}
