import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Row, Col, Sub, TopicsWrapper } from './style'

import textEN from 'src/pages/seguranca/assets/data/translate/dicas/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/dicas/textPT.json'
import { ContentType } from 'src/pages/seguranca/assets/data/translate/dicas/types'

interface IDicas {
  lang: string;
}

type HowToDoProps = {
  text: string;
}

type AvoidProps = {
  text: string;
}

export const Dicas = ({ lang }: IDicas) => {
  const { title, alwaysDo, whatYouShouldAvoid, howToDo, avoid }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <Section className='py-5'>
      <div className='container'>
        <Row className='row mb-3'>
          <div className='col-12 text-md-center mb-lg-3'>
            <h2>{title}</h2>
          </div>
        </Row>
        <Row className='row'>
          <div className='col-12 col-md-6'>
            <Col className='px-4 py-4 rounded-5 mb-3'>
              <Sub>{alwaysDo}</Sub>
              {howToDo.map((item: HowToDoProps) => (
                <TopicsWrapper className='d-flex align-items-center mb-xl-3' key={item.text}>
                  <div className='mr-3'>
                    <OrangeIcon size='SM' color='#00AA4F' icon='check' />
                  </div>
                  <p className='text-grayscale--500 mb-0'>{item.text}</p>
                </TopicsWrapper>
              ))}
            </Col>
          </div>
          <div className='col-12 col-md-6 '>
            <Col className='px-4 py-4 rounded-5'>
              <Sub>{whatYouShouldAvoid}</Sub>
              {avoid.map((item: AvoidProps) => (
                <TopicsWrapper className='d-flex align-items-center mb-xl-3' key={item.text}>
                  <div className='mr-3'>
                    <OrangeIcon size='SM' color='#F56A50' icon='exit' />
                  </div>
                  <p className='text-grayscale--500 mb-0'>{item.text}</p>
                </TopicsWrapper>
              ))}
            </Col>
          </div>
        </Row>
      </div>
    </Section>
  )
}
