import styled from 'styled-components'
import { sand } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${sand};
  
  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/seguranca-dobra2/image.webp');
    background-repeat: no-repeat;
    background-size: contain;
    height: 322px;
  }

  @media ${device.desktopLG} {
    height: 429px;
  }

  @media ${device.desktopXL} {
    height: 604px;
  }

  a {
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`
